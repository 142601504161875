import payload_plugin_9sv9bD0adOYaclHbEKV7gvPRrm1_fGdowcyxWbvFh2A from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_vrFsZFF5bSekPAgZo0LFFfwn1_pnFWhVVimiiYmc5oA from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1tGaF7cxULYaguNeCfItjplkdMWYFTI259Wb6Ha13hA from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Y3ON1HYJ3oNnKZsh577aFpIBIDC62sHKx3ha_fFGdI8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_3J0EzqmuTnXFkvXiQke_KR4HBB3_LmKoH6q0d9XVOoA from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_xLUkYE89o1y1pErOcN_bCTcPD7Ww4X5F7JIDWA76mqA from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yvAljzhrTDR_4Lbl6Y0ZkxOz6EpDhilxzm5zh3hmhMw from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Sn8_vPNL4LCXEeChr4FiJP6Dg9614zpS1CARuz5n_DY from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_D30CLMdVl0hFY1BWDqB_YSvHNIZGv1byehN6qOYhO1Y from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_HY6yFZH54u6iHkYi7_KWf0pncmnwdIW7KpUGZMnSNEw from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_4QhFENq_1tpqiAZzDB8274Fq2TWwa3_YVpBE8A5rwl8 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/apps/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_c3rs2PbdN1HNij_Mj6mEmUM_G7V7Gm8CDL_5KymQA9g from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@azure+identity@4.7.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint_tzu5cdikpte6qhn3z42m2yqqqe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/vercel/path0/apps/webapp/.nuxt/unocss.mjs";
import plugin_J9CzNK2lBJRMT4KsFCDsQF2KqDUvennhdZXM70ZsHuA from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import siteConfig_Q5W9DhK1SJZHE82VbfIMLylb8ed5Cc7hV_sMhyhxgVQ from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.35.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_qre_vRCtHfJudTzLQUsIh3kRs9eq10928v6__yylDUk from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.35.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_e7d4_0yHf55E5MDiQMdrBJ7yh2pwVL_a23V5_ygB6BE from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.35.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import _00_setup_R8KGwfprmoAaFg_hpL_vbhPvNN9gn3g_J4L9ejlb3Nk from "/vercel/path0/packages/ui/plugins/00.setup.ts";
import _0_routeRules_ab3fGFxwXnBjK7OEGvF2fPXV3Z2yjRhk9_W7EWXh3BU from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.35.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_qTPe3JU66awqu1EihXd2odkvGpIqjfqBQrb8nHXDInI from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.35.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_9sv9bD0adOYaclHbEKV7gvPRrm1_fGdowcyxWbvFh2A,
  revive_payload_client_vrFsZFF5bSekPAgZo0LFFfwn1_pnFWhVVimiiYmc5oA,
  unhead_1tGaF7cxULYaguNeCfItjplkdMWYFTI259Wb6Ha13hA,
  router_Y3ON1HYJ3oNnKZsh577aFpIBIDC62sHKx3ha_fFGdI8,
  _0_siteConfig_3J0EzqmuTnXFkvXiQke_KR4HBB3_LmKoH6q0d9XVOoA,
  payload_client_xLUkYE89o1y1pErOcN_bCTcPD7Ww4X5F7JIDWA76mqA,
  navigation_repaint_client_yvAljzhrTDR_4Lbl6Y0ZkxOz6EpDhilxzm5zh3hmhMw,
  check_outdated_build_client_Sn8_vPNL4LCXEeChr4FiJP6Dg9614zpS1CARuz5n_DY,
  view_transitions_client_D30CLMdVl0hFY1BWDqB_YSvHNIZGv1byehN6qOYhO1Y,
  chunk_reload_client_HY6yFZH54u6iHkYi7_KWf0pncmnwdIW7KpUGZMnSNEw,
  plugin_vue3_4QhFENq_1tpqiAZzDB8274Fq2TWwa3_YVpBE8A5rwl8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_c3rs2PbdN1HNij_Mj6mEmUM_G7V7Gm8CDL_5KymQA9g,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  plugin_J9CzNK2lBJRMT4KsFCDsQF2KqDUvennhdZXM70ZsHuA,
  siteConfig_Q5W9DhK1SJZHE82VbfIMLylb8ed5Cc7hV_sMhyhxgVQ,
  inferSeoMetaPlugin_qre_vRCtHfJudTzLQUsIh3kRs9eq10928v6__yylDUk,
  titles_e7d4_0yHf55E5MDiQMdrBJ7yh2pwVL_a23V5_ygB6BE,
  _00_setup_R8KGwfprmoAaFg_hpL_vbhPvNN9gn3g_J4L9ejlb3Nk,
  _0_routeRules_ab3fGFxwXnBjK7OEGvF2fPXV3Z2yjRhk9_W7EWXh3BU,
  defaults_qTPe3JU66awqu1EihXd2odkvGpIqjfqBQrb8nHXDInI
]