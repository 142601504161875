
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93t2jG7BJiaQv8e2EwzuufCIpsP31wqy_b2vC539snAJYMeta } from "/vercel/path0/apps/webapp/pages/[...slug].vue?macro=true";
import { default as indexZtvbYAGgDvZkaEV4wo5YfVJKURAq6IFzuz3v0OxZSTwMeta } from "/vercel/path0/apps/webapp/pages/addons/zoho/index.vue?macro=true";
import { default as recordgcAnKeGJAZ3MdvRo4WH9FAjG741DiotvJz5Juu3oZI4Meta } from "/vercel/path0/apps/webapp/pages/addons/zoho/record.vue?macro=true";
import { default as indexXcGYOIclPB_rMkncVqvwruQm8aNtn_0b49WoW_QBhY8Meta } from "/vercel/path0/apps/webapp/pages/auth/index.vue?macro=true";
import { default as collectDv_45Z_Gm_458MEXAfePMFJjTuxUww75fACrvEaoQXkWfdQMeta } from "/vercel/path0/apps/webapp/pages/collect.vue?macro=true";
import { default as _91id_93XrV1flHvrdm5S3o4cLIkuZYsFG8rM7TEMNgOoR4F75wMeta } from "/vercel/path0/apps/webapp/pages/contact-form/[id].vue?macro=true";
import { default as player78oASihN3ppt5RZGxxxa2pEYN7LUfbfTsUq_NzKXHSkMeta } from "/vercel/path0/apps/webapp/pages/embed/player.vue?macro=true";
import { default as analyticsI6bbfmT8GugEYSgsaqjpySUMiYa30CoXtsDdAp9bkpUMeta } from "/vercel/path0/apps/webapp/pages/index/analytics.vue?macro=true";
import { default as integrationsDlPqb5oWRZQ_Dop41mh6ZJdK16tK91TBtsbI2qdqi0cMeta } from "/vercel/path0/apps/webapp/pages/index/integrations.vue?macro=true";
import { default as recordings_tg1XOCC95D4CCSWu8QKEZPcYJF5s6epzfPdV8H2X6QMeta } from "/vercel/path0/apps/webapp/pages/index/recordings.vue?macro=true";
import { default as redeemkBpb98Cd7_458L_45INtC2JOFCLUTjQ__ymc7A_45OwXKThSYMeta } from "/vercel/path0/apps/webapp/pages/index/redeem.vue?macro=true";
import { default as settings_46collectfeFhHkJ7Ph84ofuN4Xin3U_458Uj2OgOCgrGpdUKXFSWIMeta } from "/vercel/path0/apps/webapp/pages/index/settings.collect.vue?macro=true";
import { default as settings_46form13JVWYq_sLxKN56nB9fWSq03RHSOghm_45sh_45pGATbMNsMeta } from "/vercel/path0/apps/webapp/pages/index/settings.form.vue?macro=true";
import { default as settings_46paymentsNzlm7vAGcBy1a87cRzb1ijs57QltefHZbpLu6lxPagMMeta } from "/vercel/path0/apps/webapp/pages/index/settings.payments.vue?macro=true";
import { default as settingsv1l6nZeIPf_45GzNyp_QDxOfiM_45UZpvXjeG02loDzvGAYMeta } from "/vercel/path0/apps/webapp/pages/index/settings.vue?macro=true";
import { default as index26LXKywNM_45lV_45017Cx_45uLVu3aTWWnnilnY6t0WD3juAMeta } from "/vercel/path0/apps/webapp/pages/index.vue?macro=true";
import { default as joinrVGOs_45P847rRcAePm84WFWC70qd0d5LslYnAS58DzqcMeta } from "/vercel/path0/apps/webapp/pages/join.vue?macro=true";
import { default as listen4Wq_45r82o5n8htTjZFiBCciH_45rYaacLJPepKaOa_458CnkMeta } from "/vercel/path0/apps/webapp/pages/listen.vue?macro=true";
import { default as loginZHfTzCJX_7ZEpRho_45mTb8kaOl1NeM5ZA60XDfi4N_45c8Meta } from "/vercel/path0/apps/webapp/pages/login.vue?macro=true";
import { default as paymentYMQceGL0AqMNKvgumxFBWcO9z8nnPYKGnPKPTQVB6PQMeta } from "/vercel/path0/apps/webapp/pages/payment.vue?macro=true";
import { default as frontGFm19T8pdbM3l95Pn9I0SKiCxJ5x41_Gz58Oo00FxzIMeta } from "/vercel/path0/apps/webapp/pages/record/front.vue?macro=true";
import { default as gmailTN_fwL_45I1dFepkhUvj8_45ZZXhC83rR2Mwbhjj579HUQkMeta } from "/vercel/path0/apps/webapp/pages/record/gmail.vue?macro=true";
import { default as indexO8eQ1kEc9J5cOrCMx1JXwwcZDBFJY5Mb1ac39SezGPwMeta } from "/vercel/path0/apps/webapp/pages/record/index.vue?macro=true";
import { default as outlookH0ohYFxh_4502UU_45oY3bPxghvFVB7UsHjWf2bGE_BpIO0Meta } from "/vercel/path0/apps/webapp/pages/record/outlook.vue?macro=true";
import { default as shareSajdBMpJoI3k2mc_45liP22iNf9vKXAbLJAVBm33OrkW0Meta } from "/vercel/path0/apps/webapp/pages/record/share.vue?macro=true";
import { default as zendesku6GjmeIq1WFWDAeE4sabT63ODPB_rYoVCKNPPMxQ7lIMeta } from "/vercel/path0/apps/webapp/pages/record/zendesk.vue?macro=true";
import { default as canny_45redirect6lubAZPVBZU17tQZiYdZv2iHre9Ad2UsFZ2VzSjZQ9AMeta } from "/vercel/path0/apps/webapp/pages/static/canny-redirect.vue?macro=true";
import { default as payment_45success4Xm1TlYrn3J9pqkToHabNU1itlUcp8hSL6_45EfUEOTQEMeta } from "/vercel/path0/apps/webapp/pages/static/payment-success.vue?macro=true";
import { default as unsupported_45browserAHDpE1MyMzTiyuAkOC5twXGNkGG_SwnaBuzvRlUAIcMMeta } from "/vercel/path0/apps/webapp/pages/static/unsupported-browser.vue?macro=true";
import { default as indexIiUPCQMr5PKQ1nrX8rCJYIJET8Irw5eFjr3aRL7129IMeta } from "/vercel/path0/apps/microsoft-teams/pages/teams/index.vue?macro=true";
import { default as recordti6hvnIRvCUL3FBCHCQFaKGZo6Vp7m6LhvdwU8Vz_45xcMeta } from "/vercel/path0/apps/microsoft-teams/pages/teams/record.vue?macro=true";
import { default as upgradeYLlcsKwABaJ4lPWIz2IE5peOt2cp_HTqzTslZIoB3pgMeta } from "/vercel/path0/apps/webapp/pages/upgrade.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/apps/webapp/pages/[...slug].vue")
  },
  {
    name: "addons-zoho",
    path: "/addons/zoho",
    component: () => import("/vercel/path0/apps/webapp/pages/addons/zoho/index.vue")
  },
  {
    name: "addons-zoho-record",
    path: "/addons/zoho/record",
    component: () => import("/vercel/path0/apps/webapp/pages/addons/zoho/record.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexXcGYOIclPB_rMkncVqvwruQm8aNtn_0b49WoW_QBhY8Meta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/auth/index.vue")
  },
  {
    name: "collect",
    path: "/collect/:id",
    meta: collectDv_45Z_Gm_458MEXAfePMFJjTuxUww75fACrvEaoQXkWfdQMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/collect.vue")
  },
  {
    name: "contact-form-id",
    path: "/contact-form/:id()",
    meta: _91id_93XrV1flHvrdm5S3o4cLIkuZYsFG8rM7TEMNgOoR4F75wMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/contact-form/[id].vue")
  },
  {
    name: "embed-player",
    path: "/embed/player/:id",
    meta: player78oASihN3ppt5RZGxxxa2pEYN7LUfbfTsUq_NzKXHSkMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/embed/player.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index26LXKywNM_45lV_45017Cx_45uLVu3aTWWnnilnY6t0WD3juAMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index.vue"),
    children: [
  {
    name: "index-analytics",
    path: "/analytics/:id",
    meta: analyticsI6bbfmT8GugEYSgsaqjpySUMiYa30CoXtsDdAp9bkpUMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/analytics.vue")
  },
  {
    name: "index-integrations",
    path: "integrations",
    component: () => import("/vercel/path0/apps/webapp/pages/index/integrations.vue")
  },
  {
    name: "index-recordings",
    path: "recordings",
    meta: recordings_tg1XOCC95D4CCSWu8QKEZPcYJF5s6epzfPdV8H2X6QMeta || {},
    alias: ["/"],
    component: () => import("/vercel/path0/apps/webapp/pages/index/recordings.vue")
  },
  {
    name: "index-redeem",
    path: "redeem",
    meta: redeemkBpb98Cd7_458L_45INtC2JOFCLUTjQ__ymc7A_45OwXKThSYMeta || {},
    alias: ["/redeem/:code?"],
    component: () => import("/vercel/path0/apps/webapp/pages/index/redeem.vue")
  },
  {
    name: "index-settings.collect",
    path: "/settings/collect",
    meta: settings_46collectfeFhHkJ7Ph84ofuN4Xin3U_458Uj2OgOCgrGpdUKXFSWIMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/settings.collect.vue")
  },
  {
    name: "index-settings.form",
    path: "/settings/form",
    meta: settings_46form13JVWYq_sLxKN56nB9fWSq03RHSOghm_45sh_45pGATbMNsMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/settings.form.vue")
  },
  {
    name: "index-settings.payments",
    path: "/settings/payments",
    meta: settings_46paymentsNzlm7vAGcBy1a87cRzb1ijs57QltefHZbpLu6lxPagMMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/settings.payments.vue")
  },
  {
    name: "index-settings",
    path: "settings",
    meta: settingsv1l6nZeIPf_45GzNyp_QDxOfiM_45UZpvXjeG02loDzvGAYMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/settings.vue")
  }
]
  },
  {
    name: "join",
    path: "/join",
    meta: joinrVGOs_45P847rRcAePm84WFWC70qd0d5LslYnAS58DzqcMeta || {},
    alias: ["/join/:code?"],
    component: () => import("/vercel/path0/apps/webapp/pages/join.vue")
  },
  {
    name: "listen",
    path: "/listen/:id",
    meta: listen4Wq_45r82o5n8htTjZFiBCciH_45rYaacLJPepKaOa_458CnkMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/listen.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginZHfTzCJX_7ZEpRho_45mTb8kaOl1NeM5ZA60XDfi4N_45c8Meta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/login.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/vercel/path0/apps/webapp/pages/payment.vue")
  },
  {
    name: "record-front",
    path: "/record/front",
    meta: frontGFm19T8pdbM3l95Pn9I0SKiCxJ5x41_Gz58Oo00FxzIMeta || {},
    alias: ["/integration/front"],
    component: () => import("/vercel/path0/apps/webapp/pages/record/front.vue")
  },
  {
    name: "record-gmail",
    path: "/record/gmail/:id",
    meta: gmailTN_fwL_45I1dFepkhUvj8_45ZZXhC83rR2Mwbhjj579HUQkMeta || {},
    alias: ["/gmail-record/:id"],
    component: () => import("/vercel/path0/apps/webapp/pages/record/gmail.vue")
  },
  {
    name: "record",
    path: "/record",
    meta: indexO8eQ1kEc9J5cOrCMx1JXwwcZDBFJY5Mb1ac39SezGPwMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/record/index.vue")
  },
  {
    name: "record-outlook",
    path: "/record/outlook",
    meta: outlookH0ohYFxh_4502UU_45oY3bPxghvFVB7UsHjWf2bGE_BpIO0Meta || {},
    alias: ["/outlook"],
    component: () => import("/vercel/path0/apps/webapp/pages/record/outlook.vue")
  },
  {
    name: "record-share",
    path: "/record/share/:id",
    meta: shareSajdBMpJoI3k2mc_45liP22iNf9vKXAbLJAVBm33OrkW0Meta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/record/share.vue")
  },
  {
    name: "record-zendesk",
    path: "/record/zendesk",
    component: () => import("/vercel/path0/apps/webapp/pages/record/zendesk.vue")
  },
  {
    name: "static-canny-redirect",
    path: "/static/canny-redirect",
    meta: canny_45redirect6lubAZPVBZU17tQZiYdZv2iHre9Ad2UsFZ2VzSjZQ9AMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/static/canny-redirect.vue")
  },
  {
    name: "static-payment-success",
    path: "/static/payment-success",
    meta: payment_45success4Xm1TlYrn3J9pqkToHabNU1itlUcp8hSL6_45EfUEOTQEMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/static/payment-success.vue")
  },
  {
    name: "static-unsupported-browser",
    path: "/static/unsupported-browser",
    meta: unsupported_45browserAHDpE1MyMzTiyuAkOC5twXGNkGG_SwnaBuzvRlUAIcMMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/static/unsupported-browser.vue")
  },
  {
    name: "teams",
    path: "/teams",
    component: () => import("/vercel/path0/apps/microsoft-teams/pages/teams/index.vue")
  },
  {
    name: "teams-record",
    path: "/teams/record",
    component: () => import("/vercel/path0/apps/microsoft-teams/pages/teams/record.vue")
  },
  {
    name: "upgrade",
    path: "/upgrade",
    component: () => import("/vercel/path0/apps/webapp/pages/upgrade.vue")
  }
]